/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../components/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import { MdArrowForward } from "react-icons/md";
import { MdOutlineArrowBack } from "react-icons/md";
import { CgMoreVerticalO } from "react-icons/cg";
import { MdOutlineCameraAlt } from "react-icons/md";
import { MdOutlineCloudUpload } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import Webcam from "react-webcam";
import QRCode from 'qrcode.react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import nationalityData from './nationality.json';
import bloodData from './blood.json'
import BusinessCard from './card';

let API_URL = `https://nftkyc.greatcrypto.network/api/v1`
let API_URL_GCN = `https://nftkyc.greatcrypto.network/api/v2`





function Dashboard() {
  const navigate = useNavigate();

  const [img, setImg] = useState(null);
  const [verifition, setVerifition] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);
  const [Kyc, setKyc] = useState(false);
  const [number, setNumber] = useState(0);
  const [selfieImage, setSelfieImage] = useState(null);
  const [frentSideImage, setFrentSideImage] = useState(null);
  const [ImageSign, setImageSign] = useState(null);
  const [backSideImage, setBackSideImage] = useState(null);
  // const [comingsoon, setComingsoon] = useState(false);
  const webcamRef = useRef(null);
  const { address } = useParams()
  const docTypeData = ["Passport", "Votar ID", "PAN Card", "Aadhar Card"]
  const intialStateData = {
    email: "",
    docType: "",
    frontImage: "",
    backImage: "",
    selfie: "",
    signature: "",
    gender: "",
    bloodGroup: "",
    nationality: "",
    placeOfBirth: "",
    residenceAddress: "",
    dob: "",
    name: ""
  }
  const [formDataRes, setFormData] = useState(intialStateData)
  const [isWebcamAvailable, setIsWebcamAvailable] = useState(false);
  const [fill, setFill] = useState(false)
  const [zill, setZill] = useState(false)
  const [second, setSecond] = useState(false)


  const getProfile = async () => {
    let resData = {
      address: address
    }
    const res = await fetch(`${API_URL}/getKYCInfoByAddres`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resData),
    });

    const dataRes = await res.json();
    if (String(dataRes.data.status) === String(101) || String(dataRes.data.status) === String(104)) {
      setVerifition(true)
    } else if (String(dataRes.data.status) === String(102)) {
      setPending(true)
    } else if (String(dataRes.data.status) === String(103)) {
      setSuccess(true)

    }
  }

  useEffect(() => {
    if (!fill && !second) {
      getProfile();
    };
  }, []);


  const videoConstraints = {
    width: 240,
    height: 240,
    facingMode: "user",
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
    handelUploadSelfie(imageSrc)
  }, [webcamRef]);




  const handelFirstNext = (num) => {

    setZill(true)
    setVerifition(false)
    setNumber(26)

  }

  const handelValidation = () => {
    let isvalid = true
    const email = formDataRes.email
    if (!formDataRes.email || !email.includes("@")) {
      alert("please enter valid email!")
      isvalid = false
      return
    }
    if (isvalid) {
      handelFirstNext()
    }

  }

  const handelUserInfoNext = (num) => {
    setFill(true)
    setZill(false)
  }

  const handelValidationUserInfo = () => {
    let isvalid = true
    if (!formDataRes.email || !formDataRes.gender || !formDataRes.nationality || !formDataRes.bloodGroup || !formDataRes.dob || !formDataRes.placeOfBirth || !formDataRes.residenceAddress || !formDataRes.name ) {
      alert("please fill field all are mandatory")
      isvalid = false
      return
    }
    if (isvalid) {
      setNumber(51)
      handelUserInfoNext()
    }

  }

  const handelSecondNext = async (num) => {
    if (formValidationhandel()) {
      try {
        let resData = {
          address: address,
          imageFront: frentSideImage,
          imageBack: backSideImage
        }
        const response = await fetch(`${API_URL}/saveKycDocAadhar`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(resData),
        });

        const responseData = await response.json();

        if (responseData.data) {
          setSecond(true)
          setFill(false)
          setNumber(76)
        }
        else {
          console.log('Registration failed');
        }

      } catch (error) {
        console.error('Error during registration:', error.message);
      }
    }
    else {
      toast.error("Failed to register")
      return "failed registration"
    }
  }

  const handelSelfie = async (num) => {
    try {
      let resData = {
        address: address,
        imageFront: selfieImage,
      }
      const response = await fetch(`${API_URL}/saveKycDocSelfie`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(resData),
      });

      const responseData = await response.json();

      if (!responseData.error) {
        setKyc(true)
        setNumber(100)
      }
      else {
        console.log('Registration failed');
      }

    } catch (error) {
      console.error('Error during registration:', error.message);
    }
  }
  const handelKyc = async (num) => {

    if (ImageSign !== "" && ImageSign !== undefined) {
      try {
        let resData = {
          address: address,
          // imageFront: selfieImage,
        }
        const response = await fetch(`${API_URL}/createKYC`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(resData),
        });

        const responseData = await response.json();

        if (!responseData.error) {

          let resData1 = {
            address: address,
            photo: selfieImage,
            email: formDataRes.email,
            name: formDataRes.name,
            dob: formDataRes.dob,
            gender: formDataRes.gender,
            bloodGroup: formDataRes.bloodGroup,
            nationality: formDataRes.nationality,
            dateOfIssue: formDataRes.dateOfIssue,
            dateOfExpired: formDataRes.dateOfExpired,
            signature: ImageSign,
            residenceAddress: formDataRes.residenceAddress,
            placeOfBirth: formDataRes.placeOfBirth,
          }
          const response = await fetch(`${API_URL}/register`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(resData1),
          });

          const responseData = await response.json();

          if (!responseData.error) {

            setSecond(false)
            let resData2 = {
              address: address
            }
            const res = await fetch(`${API_URL}/getKYCInfoByAddres`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(resData2),
            });

            const dataRes = await res.json();
            if (String(dataRes.data.status) === String(101)) {
              setVerifition(true)
            } else if (String(dataRes.data.status) === String(102)) {
              let resData3 = {
                address: address
              }
              const res = await fetch(`${API_URL}/updateUserIdInKyc`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(resData3),
              });

              const dataRes = await res.json();
              if (!dataRes.error) {
                navigate(`/pending`);
              }


            } else if (String(dataRes.data.status) === String(103)) {
              setSuccess(true)
            } else if (String(dataRes.data.status) === String(104)) {
              let resData2 = {
                address: address
              }
              const res = await fetch(`${API_URL}/updateUserStatus`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(resData2),
              });

              const dataRes = await res.json();
              if (!dataRes.error) {
                setPending(true)
              }
            }
          }
          else {
            toast.error(responseData.message)
            console.log('Registration failed');
          }
        }
        else {
          toast.error(responseData.message)
          console.log('Registration failed');
        }

      } catch (error) {
        toast.error(error.message)
        console.error('Error during registration:', error.message);
      }
    }
    else {
      toast.error("Fill All details, Registration failed");
    }
  }
  const handelResUpload = async (event) => {
    try {
      var formData = new FormData();
      formData.append('image2', event.target.files[event.target.files.length - 1]);
      const response = await axios.post(`${API_URL_GCN}/uploadImage2`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });
      const responseData = response.data;

      console.log(responseData);

      if (responseData.data) {
        setBackSideImage(responseData.data.imageName)
      } else {
        console.log('Registration failed');
      }

    } catch (error) {
      console.error('Error during registration:', error.message);
    }
  }
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formDataRes,
      [name]: value
    });
  };

  const handelUploadSign = async (event) => {

    try {
      var formData = new FormData();
      formData.append('image2', event.target.files[event.target.files.length - 1]);
      const response = await axios.post(`${API_URL_GCN}/uploadImage2`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });
      const responseData = response.data;
      console.log(responseData);

      if (responseData.data) {
        setImageSign(responseData.data.imageName)
      } else {
        console.log('Registration failed');
      }

    } catch (error) {
      console.error('Error during registration:', error.message);
    }
  }

  const handelUpload = async (event) => {
    try {
      var formData = new FormData();
      formData.append('image', event.target.files[event.target.files.length - 1]);
      const response = await axios.post(`${API_URL_GCN}/uploadImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const responseData = response.data;
      console.log(responseData);
      if (responseData.data) {
        setFrentSideImage(responseData.data.imageName)
      } else {
        console.log('Registration failed');
      }

    } catch (error) {
      console.error('Error during registration:', error.message);
    }
  }
  const handelUploadSelfie = async (imageSrc) => {
    try {
      const resData = {
        imageData: imageSrc
      };
    
      const response = await axios.post(`${API_URL_GCN}/uploadBase64`, resData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    
      const responseData = response.data;
    
      if (!responseData.error) {
        console.log(responseData);
        setSelfieImage(responseData.data.Location);
      } else {
        console.log('Registration failed');
      }
    } catch (error) {
      console.error('Error during registration:', error.message);
    }
  }
  const formValidationhandel = () => {
    if (formDataRes.email === "" && formDataRes.email === undefined) {
      alert("Please enter a email")
      return false
    }
    else if (formDataRes.name === "" && formDataRes.name === undefined) {
      alert("Please enter a name")
      return false
    }
    else if (formDataRes.dob === "" && formDataRes.dob === undefined) {
      alert("Please enter a date of birth")
      return false
    }
    else if (formDataRes.gender === "" && formDataRes.gender === undefined) {
      alert("Please enter a gender")
      return false
    }
    else if (formDataRes.docType && formDataRes.docType === undefined) {
      alert("Please select a doc type")
      return false
    }
    else if (formDataRes.placeOfBirth === "" && formDataRes.placeOfBirth === undefined) {
      alert("Please enter a place of birth")
      return false
    }
    else if (formDataRes.residenceAddress === "" && formDataRes.residenceAddress === undefined) {
      alert("Please enter a residence address")
      return false
    }
    else if (formDataRes.nationality === "" && formDataRes.nationality === undefined) {
      alert("Please enter a nationality")
      return false
    }
    else if (formDataRes.bloodGroup === "" && formDataRes.bloodGroup === undefined) {
      alert("please enter a blood group")
      return false
    }
    else if (frentSideImage === "" && frentSideImage === undefined && frentSideImage === null) {
      alert("please enter fornt side Image of a documaent")
      return false
    }
    else if (backSideImage === "" && backSideImage === undefined && backSideImage === null) {
      alert("please enter back side Image of a documaent")
      return false
    }
    return true
  }


  return (
    <>
      <Header />
      {!pending && !success && <div className="container mt-5">
        <div className="w-full bg-orange-200 rounded-full h-3.5 ">
          <div className="bg-[#f88d04] h-3.5 rounded-full" style={{ width: `${number}%` }}>
            <div style={{ position: 'relative' }}>
              <FaCircleCheck className='-top-1 ' style={{ position: 'absolute', left: '100%', transform: 'translateX(-50%)' }} size={25} color='#f88d04' />
            </div>
          </div>
        </div>
      </div>}

      {success && 
        <BusinessCard />}
      {pending && <section>
        <div className='container'>
          <div className='mail-tab mt-4'>
            <div>
              <label>Pending</label>
            </div>
          </div>
        </div>
      </section>}


      {number === 0 && number < 25 && (verifition &&
        <section>
          <div className='container'>
            <div className='mail-tab mt-4 '>
              <div>
                <h3 className='text-center'>Verify Your identity </h3>
                <input className='mt-4' placeholder='Enter Your Email' id='email' name='email' value={formDataRes.email} onChange={handleOnChange}></input>
                <button className='flex items-center justify-center mt-3 bg-[#f88d04] text-white px-6 py-2 text-base	rounded	 w-100' onClick={() => handelValidation(48)}>
                  Next <MdArrowForward />
                </button>
              </div>
            </div>
          </div>
        </section>)
      }
      {number >= 25 && number < 50 && zill &&
        <section>
          <div className='container'>
            <div className='mail-tab mt-4'>
              <div className='space-y-6'>

                <div className=''>
                  <h5 className=''>Name </h5>
                  <input className='' placeholder='Enter name' id='name' name='name' value={formDataRes.name} onChange={handleOnChange}></input>
                </div>

                <div className=''>
                  <h5 className='text-bold'>Date of birth </h5>
                  <input className='' placeholder='Date of birth' id='dob' name='dob' type='date' value={formDataRes.dob} onChange={handleOnChange}></input>
                </div>


                <div className=''>
                  <h5 className='  '>Gender </h5>
                  <select className='text-center w-full h-[39px] rounded-md' name='gender' onChange={handleOnChange}>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  {/* <input className='' placeholder='Gender' id='gender' name='gender' value={formDataRes.gender} onChange={handleOnChange}></input> */}
                </div>

                <div className=''>
                  <h5 className=''>Blood group </h5>
                  <select className='text-center w-full h-[39px] rounded-md' name='bloodGroup' value={formDataRes.bloodGroup} onChange={handleOnChange}>
                    <option value={""}>Select Blood Group</option>
                    {bloodData.bloodGroups.map((blood, index) => {
                      return <option value={blood.description} key={index}>{blood.group + blood.rh}</option>
                    })}
                  </select>
                  {/* <input className='' placeholder='Blood group' id='bloodGroup' name='bloodGroup' value={formDataRes.bloodGroup} onChange={handleOnChange}></input> */}
                </div>

                <div className=''>
                  <h5 className=''>Nationality </h5>
                  <select className='text-center w-full h-[39px] rounded-md' name='nationality' onChange={handleOnChange}>
                    <option value="">Select Nationality</option>
                    {nationalityData.nationality.map((count, index) => {
                      return <option value={count.name} key={index}>{count.name}</option>
                    })}
                  </select>
                </div>

                <div className=''>
                  <h5 className=''>Residence address </h5>
                  <input className='' placeholder='Residence address' id='residenceAddress' name='residenceAddress' value={formDataRes.residenceAddress} onChange={handleOnChange}></input>
                </div>

                <div className=''>
                  <h5 className=''>Place of birth </h5>
                  <input className='' placeholder='Place of birth' id='placeOfBirth' name='placeOfBirth' value={formDataRes.placeOfBirth} onChange={handleOnChange}></input>
                </div>


                <div >
                  <h5 className=''>Signature </h5>
                  <div className='flex'>
                    <input className='' placeholder='Signature' type='file' id='signature' name='signature' onChange={handelUploadSign}></input>
                    {ImageSign && <img src={`${ImageSign}`} style={{ width: "40px" }} />}
                  </div>
                </div>

                <button className='flex items-center justify-center mt-3 bg-[#f88d04] text-white px-6 py-2 text-base	rounded	 w-100' onClick={() => handelValidationUserInfo(48)}>
                  Next <MdArrowForward />
                </button>
              </div>
            </div>
          </div>
        </section>
      }
      {number > 50 && number <= 75 && fill && <section>
        <div className='container'>
          <div className='profile-tab mt-4'>
            <div>
              <div className='row'>
                <div className='col-md-4'>
                  <button className='flex items-center gap-1'
                  // onClick={() => handelFirstNext(0)}
                  ><MdOutlineArrowBack />Back</button>
                </div>
                <div className='col-md-4 flex justify-center text-center'>
                  <div>
                    <h3>Submit your document</h3>

                    <p>Choose the type of document</p>
                  </div>
                </div>
                <div className='col-md-4 flex justify-center te'>
                </div>
              </div>
              <div className=''>
                <div >
                  <div className="select-wrapper flex justify-center w-100 m-auto ">
                    <select className="select " name='docType'
                      onChange={handleOnChange} >
                      <option>Select</option>
                      {docTypeData.map((ele, index) => {
                        return <option value={ele} key={index}>{ele}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className='upload-doc-sec mt-4'>
                <div className='row'>
                  <div className='col-md-8'>
                    <p className='flex items-center gap-1'><CgMoreVerticalO /> How to make a document photo?</p>
                    <div>
                      <div className='row FRONT-SIDE-doc'>
                        <span>FRONT SIDE</span>
                        <div className='col-md-6 mt-3'>
                          <div className=''>
                            <div>
                              <img src={frentSideImage ? frentSideImage : "/images/ID_front.svg"} alt="" className='h-[180px]' />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 mt-3 space-y-2'>
                          <div className='file file--upload '>
                            <label htmlFor='input-file'>
                              <MdOutlineCameraAlt /> Take a Photo
                            </label>
                            <input id='input-file' type='file' name='image' onChange={handelUpload} />
                          </div>
                          <div className='file file--upload'>
                            <label htmlFor='input-file'>
                              <MdOutlineCloudUpload /> Upload File
                            </label>
                            <input id='input-file' type='file' name='image' onChange={handelUpload} />
                          </div>
                        </div>
                      </div>
                      <div className='row FRONT-SIDE-doc'>
                        <span>BACK SIDE</span>
                        <div className='col-md-6 mt-3'>
                          <div className=''>
                            <div>
                              <img src={backSideImage ? backSideImage : "/images/ID_back.svg"} alt="" className='h-[180px]' />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 mt-3 space-y-2'>
                          <div className='file file--upload '>
                            <label for='input-file1'>
                              <MdOutlineCameraAlt /> Take a Photo
                            </label>
                            <input id='input-file1' type='file' name='image2' onChange={handelResUpload} />
                          </div>
                          <div className='file file--upload'>
                            <label for='input-file1'>
                              <MdOutlineCloudUpload />Upload File
                            </label>
                            <input id='input-file1' type='file' name='image2' onChange={handelResUpload} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className='flex items-center justify-center mt-3 bg-[#f88d04] text-white px-6 py-2 text-base	rounded	 w-40 m-auto' onClick={() => handelSecondNext(100)}>
                Next <MdArrowForward />
              </button>
            </div>
          </div>

        </div>
      </section>}

      {number > 75 && second &&
        <section>
          <div className='container'>
            <div className='profile-tab mt-4'>
              {Kyc ?
                <button className='flex items-center justify-center mt-3 bg-[#f88d04] text-white px-6 py-2 text-base	rounded	 w-40 m-auto' onClick={() => handelKyc(100)}>
                  Create Kyc <MdArrowForward />
                </button>
                :
                <div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <button className='flex items-center gap-1' onClick={() => handelFirstNext(48)}>
                        <MdOutlineArrowBack />Back
                      </button>
                    </div>
                    <div className='col-md-4 flex justify-center text-center'>
                      <div>
                        <h3>Liveness Selfie</h3>
                        <p>Try Another Device</p>
                        <div className="Container">
                          {isWebcamAvailable ? (
                            img === null ? (
                              <>
                                <Webcam
                                  audio={false}
                                  mirrored={true}
                                  height={200}
                                  width={200}
                                  ref={webcamRef}
                                  screenshotFormat="image/jpeg"
                                  videoConstraints={videoConstraints}
                                />
                                <button className='flex items-center justify-center mt-3 bg-[#f88d04] text-white px-6 py-2 text-base rounded w-40 m-auto' onClick={capture}>Capture photo</button>
                              </>
                            ) : (
                              <>
                                <img src={img} alt="screenshot" />
                                <button className='flex items-center justify-center mt-3 bg-[#f88d04] text-white px-6 py-2 text-base rounded w-40 m-auto' onClick={() => setImg(null)}>Retake</button>
                              </>
                            )
                          ) : (
                            <p>Webcam not available. Please use a device with a webcam.
                              {setIsWebcamAvailable(true)}</p>
                          )}
                        </div>

                        <button className='flex items-center justify-center mt-3 bg-[#f88d04] text-white px-6 py-2 text-base rounded w-40 m-auto' onClick={() => handelSelfie(98)}>
                          Submit <MdArrowForward />
                        </button>

                      </div>
                    </div>
                    <div className='col-md-4 flex justify-center'>
                      {isWebcamAvailable && (
                        <div>
                          <h3>QR Code</h3>
                          <p>Scan this QR code</p>
                          <QRCode value={window.location.href} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>}
            </div>
          </div>
        </section>
      }
      <ToastContainer />
    </>
  );
}

export default Dashboard;
