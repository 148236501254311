import React, { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdArrowForward } from "react-icons/md";
import Header from '../components/Header';
import { useNavigate, useParams } from 'react-router-dom';

function ComingSoon() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const { address } = useParams()
  console.log('params________________params', address);



  return (
    <>
      <div className="bg-gray-900 text-white flex items-center justify-center h-screen">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">Coming Soon</h1>
          <p className="text-lg mb-8">
            We are working hard to bring you something amazing. Stay tuned!
          </p>
        </div>
      </div>
    </>
  );
}

export default ComingSoon;
