
import './App.css';
import "./style.css"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import WebcamImage from './pages/camera';
import Pending from './pages/pending'
import ComingSoon from './pages/ComingSoon';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/:address?" element={<Dashboard />} ></Route>
        <Route path="/camera" element={<WebcamImage />} ></Route>
        <Route path="/pending" element={<Pending />} ></Route>
        <Route path="/ComingSoon" element={<ComingSoon />} ></Route>
        <Route path="/Details/:address?" element={<Dashboard />}></Route>
      </Routes>
    </BrowserRouter >
  );
}

export default App;
