import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from 'react-router-dom'
import moment from 'moment'

import QRCode from 'react-qr-code';

function BusinessCard() {
    const { address } = useParams()
    const [userData, setUserData] = useState(null)
    const [jsonData, setJsonData] = useState(null)



    const getProfile = async () => {
        let resData = {
            address: address
        }
        const res = await fetch(`https://nftkyc.jamsara.com/api/v1/getKYCInfoByAddres`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(resData),
        });

        const dataRes = await res.json();
        if (dataRes.data.status === 103) {
            console.log('jjjjjjjjjjjjjjjjj', dataRes.data.kycData)
            setUserData(dataRes.data.kycData)
            setJsonData(dataRes.data.kycData.userId)

        }
    }


    useEffect(() => {
        getProfile()
    }, [address])


    const newJsonData = {
        FullName : `${jsonData?.name ? jsonData.name : ""}`,
        Gender : `${jsonData?.gender ? jsonData.gender : ""}`,
        BloodGroup : `${jsonData?.bloodGroup ? jsonData.bloodGroup : ""}`,
        Nationality : `${jsonData?.nationality ? jsonData.nationality : ""}`,
        DOB : `${jsonData?.dob ? jsonData.dob : ""}`,
        DOI : `${jsonData?.dateOfIssue ? jsonData.dateOfIssue : ""}`,
    }



    return (
        <>
            <section className='mt-10'>
                <div className='' >
                    <div className='p-8 w-11/12 2xl:w-6/12 xl:w-6/12 lg:w-6/12 sm:w-11/12 mx-auto  bg-contain main-bg' style={{ backgroundImage: 'url("/images/imgpsh_fullsize_anim (1).png")', backgroundRepeat: "no-repeat" }}>
                        <div className='flex items-center gap-6 justify-center '>
                            <div>
                            <div className='flex card-con'>
                                <div className='card-details '>
                                    <div className='font-bold text-[#121212] '>Full Name:</div>
                                    <div className='font-bold text-[#121212] '>Date of Birth:</div>
                                    <div className='font-bold text-[#121212] '>Gender:</div>
                                    <div className='font-bold text-[#121212] '>Blood Group:</div>
                                    <div className='font-bold text-[#121212] '>Nationality:</div>
                                    <div className='font-bold text-[#121212] '>Date of Issue:</div>
                                    <div className='font-bold text-[#121212] '>Date of Expired:</div>
                                </div>
                                
                                {userData && userData.userId && <div className='card-details'>
                                    <div className='font-bold text-[#121212] ml-5'>{userData.userId.name}</div>
                                    <div className='font-bold text-[#121212] ml-5'>{userData.userId.dob}</div>
                                    <div className='font-bold text-[#121212] ml-5 capitalize'>{userData.userId.gender} </div>
                                    <div className='font-bold text-[#121212] ml-5'>{userData.userId.bloodGroup}</div>
                                    <div className='font-bold text-[#121212] ml-5'>{userData.userId.nationality}</div>
                                    <div className='font-bold text-[#121212] ml-5'>{moment(userData.userId.dateOfIssue).format('MM-DD-YYYY')}</div>
                                    <div className='font-bold text-[#121212] ml-5'>{moment(userData.userId.dateOfIssue).add(10, 'years').format('MM-DD-YYYY')}</div>
                                </div>}
                                
                            </div>
                            <div >
                                        <QRCode className='mt-2 ' value={`${JSON.stringify(newJsonData)}`} size={40} mar />
                                    </div>
                            </div>
                           
                            <div className='flex items-center justify-center'>
                                <div className='space-y-2'>
                                   
                                    {/* <img className='card-image' src={userData && userData.userId && userData.userId.photo} alt="profile" /> */}
                                    <img className='card-image ' src={userData && userData.userId && userData.userId.photo} alt="profile" />
                                    

                                    <img className='card-image' src={userData && userData.userId && userData.userId.signature} alt="signature" />
                                   
                                </div>
                                  
                                <div className='flex  mx-auto card-code'>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BusinessCard;
