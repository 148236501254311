import React, { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdArrowForward } from "react-icons/md";
import Header from '../components/Header';
import { useNavigate, useParams } from 'react-router-dom';

function Pending() {
  const navigate = useNavigate();



  return (
    <>
      <Header />
      <section>
        <div className='container'>
          <div className='mail-tab mt-4'>
            <label>Pending</label>
          </div>
        </div>
      </section>
    </>
  );
}

export default Pending;
