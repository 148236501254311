

export default function Header() {
  return (
    <header className="  h-[80px] py-2 shadow-lg px-4 md:sticky top-0 bg-gray-300 z-40 w-full">

      <div className='container flex  justify-between m-auto	 items-center'>
        <div className='flex items-center space-x-6'>
          <a href="/" className="text-white flex items-center space-x-2 group hover:text-white">
            <div>
              <img src="/images/logo2.png" alt="" className='w-40' />
            </div>
          </a>
        </div>
        <div className='flex items-center space-x-6'>
          <div className='flex items-center space-x-3'>
            <button className='flex items-center justify-center rounded-full  bg-[#f88d04] text-white px-6 py-2 text-sm w-100'>
              Get Start
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
